<template>
  <div>
    <b-link :to="{ name: 'addClientApp' }">Ajouter une application cliente</b-link>
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
              v-model="searchedName"
              placeholder="Rechercher par nom"
              @keyup.enter="searchClientAppsByName"
          />
          <b-input-group-append>
            <b-button variant="outline-primary" @click="searchClientAppsByName">
              <font-awesome-icon icon="search"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col v-if="clientApps.length > 0">
        <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageSize"
            @change="loadClientApps"
        />
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col v-if="loading">
        <ClientAppListSkeleton/>
      </b-col>
      <b-col v-else>
        <ClientAppList v-if="clientApps.length > 0" :clientApps="clientApps"/>
        <span v-else>
          Aucun application cliente ne correspond à votre recherche
        </span>
      </b-col>
    </b-row>
    <b-row v-if="clientApps.length > 0">
      <b-col>
        <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageSize"
            @change="loadClientApps"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ClientAppList from "@/components/clientApps/ClientAppList";
import ClientAppListSkeleton from "@/components/clientApps/ClientAppListSkeleton.js";

export default {
  components: {
    ClientAppList,
    ClientAppListSkeleton,
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      searchedName: null,
    };
  },
  computed: {
    ...mapState({
      clientApps: (state) => state.clientApps.all,
      total: (state) => state.clientApps.total,
      pageSize: (state) => state.clientApps.pageSize,
    }),
  },
  async created() {
    await this.loadClientApps();
  },
  methods: {
    async loadClientApps(page = 1) {
      this.loading = true;
      try {
        await this.$store.dispatch("clientApps/fetchClientApps", {
          page,
          name: this.searchedName ?? null,
        });
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la récupération des applications clientes",
          text: e.message,
        });
        await this.$router.push({name: 'clientApps'})
        return;
      }
      this.loading = false;
    },
    searchClientAppsByName() {
      this.currentPage = 1;
      this.loadClientApps(this.currentPage);
    },
  },
};
</script>

